import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import { addImageSharpData } from '../util/helpers'
import { Flex, Box } from '@rebass/grid'
import {
  PageWrap,
  PageRow,
  PageExpand,
  PageGallery,
  PageHead,
  PageCopy,
} from '../components/global'
// import styled from 'styled-components'
import PreviewItem from '../components/projects/preview-item'
import NextProject from '../components/projects/next-project'

export default ({ data, pageContext }) => {
  const allImages = data.images
  const allPreviewImages = data.previewImages
  const project = data.project
  const nextProjectData = pageContext.nextProject
  // console.log('project', data, nextProjectData)

  // Build ogImage
  const foundEdges = allImages.edges.filter((edge) => {
    return edge.node.relativePath === project.image
  })
  let ogImage
  if (foundEdges.length > 0) {
    ogImage = foundEdges[0].node.publicURL
  }

  // add sharp data
  // const imagesWithSharp = addImageSharpData(project.feature, allImages)
  const imagesWithSharpNextProject = addImageSharpData(
    nextProjectData.feature,
    allImages
  )
  const previewImagesWithSharp = addImageSharpData(
    project.feature,
    allPreviewImages
  )

  const featureImages = <PreviewItem images={previewImagesWithSharp} />
  let progressRow
  if (project.progress.length > 0) {
    // console.log(project.progress, 'checkehckehckehckehk')

    const progressGrid = (
      <PageGallery data={project.progress} allImages={allImages} />
    )
    progressRow = (
      <PageRow>
        <PageExpand title="Progress" content={progressGrid} isOpen={false} />
      </PageRow>
    )
  }

  let completeRow
  if (project.complete.length > 0) {
    const completeGrid = (
      <PageGallery data={project.complete} allImages={allImages} />
    )
    completeRow = (
      <PageRow>
        <PageExpand title="Complete" content={completeGrid} isOpen={false} />
      </PageRow>
    )
  }

  const nextGrid = (
    <PreviewItem
      url={nextProjectData.id}
      images={imagesWithSharpNextProject}
      caption={`${nextProjectData.project} ${nextProjectData.suburb}`}
    />
  )
  let nextRow = (
    <PageRow>
      <NextProject title="Next" content={nextGrid} link={nextProjectData.id} />
    </PageRow>
  )

  let detailRow
  if (project.detail.length > 0) {
    const detailGrid = (
      <PageGallery data={project.detail} allImages={allImages} />
    )
    detailRow = (
      <PageRow>
        <PageExpand title="Detail" content={detailGrid} isOpen={false} />
      </PageRow>
    )
  }

  return (
    <PageWrap>
      <SEO title={`${project.project} ${project.suburb}`} image={ogImage} />
      <PageRow>
        <PageHead>
          {project.project}
          <br />
          <span>{project.suburb}</span>
        </PageHead>
      </PageRow>
      <PageRow>
        <Flex flexDirection="column">
          <Box
            ml={[0, `${(2 / 10) * 100}%`]}
            width={[1, 4 / 8]}
            px={[2, 3]}
            pt={[3, 4]}
            pb={[0, 0]}
          >
            <PageCopy>
              <p dangerouslySetInnerHTML={{ __html: project.summary }} />
            </PageCopy>
          </Box>
          <Box width={[1]} px={[0]} pb={[3, 5]}>
            {featureImages}
          </Box>
        </Flex>
      </PageRow>
      {detailRow}
      {completeRow}
      {progressRow}
      {nextRow}
    </PageWrap>
  )
}

export const query = graphql`
  query ($id: String!) {
    previewImages: allFile(
      filter: { absolutePath: { regex: "/.images/progress|complete|detail./" } }
    ) {
      edges {
        node {
          relativePath
          publicURL
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    images: allFile(
      filter: { absolutePath: { regex: "/.images/progress|complete|detail./" } }
    ) {
      edges {
        node {
          relativePath
          publicURL
          childImageSharp {
            fluid(maxWidth: 1500, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    project: projectsJson(id: { eq: $id }) {
      id
      project
      suburb
      summary
      image
      feature {
        src
        width
      }
      progress {
        caption
        src
        type
        ratio
        width
      }
      complete {
        caption
        src
        type
        ratio
        width
      }
      detail {
        caption
        src
        type
        ratio
        width
      }
    }
  }
`
