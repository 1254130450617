import React from 'react'
import { Flex, Box } from 'reflexbox/styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styled from 'styled-components'

export default ({ url, images, caption }) => {
  const cols = images.map((image, index) => {
    if (image.childImageSharp) {
      //console.log('>>>', image.childImageSharp.fluid)

      return (
        <Box
          width={[1 / 2, image.width / 10]}
          key={`box-${index}`}
          px={[2, 3]}
          pb={[3, 0]}
        >
          <Img
            fluid={image.childImageSharp.fluid}
            alt=""
            backgroundColor="#eee"
          />
        </Box>
      )
    } else {
      console.log('✘ This image does not exist:', image.src)
    }
    return false
  })

  const imageContent = (
    <RatioWrap>
      <RatioInner>
        <Flex flexWrap="wrap">{cols}</Flex>
      </RatioInner>
    </RatioWrap>
  )

  const captionContent = caption ? (
    <Flex px={[2, 3]}>
      <Caption>{caption}</Caption>
    </Flex>
  ) : null

  if (url) {
    return (
      <Wrap to={url}>
        {imageContent}
        {captionContent}
      </Wrap>
    )
  } else {
    return (
      <div>
        {imageContent}
        {captionContent}
      </div>
    )
  }
}

const Wrap = styled(Link)`
  text-decoration: none;
  &:hover {
    img {
      filter: grayscale(0%);
    }
  }
  img {
    transition: opacity 100ms ease 0s, filter 0.3s ease !important;
    filter: grayscale(100%);
  }
`

const RatioWrap = styled.div`
  width: 100%;
  padding-top: 21%;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    padding-top: 20.5%;
  }
  @media screen and (max-width: 900px) {
    overflow: auto;
    padding-top: 0px;
  }
`

const RatioInner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  @media screen and (max-width: 900px) {
    position: relative;
  }
`

const Caption = styled.span`
  ${props => props.theme.fontSizing(props.theme.font.captionSizes)};
  color: ${props => props.theme.pageTheme.fg};
  text-decoration: none;
  margin: 0;
  padding: 1em 0;
  display: block;
  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    padding-top: 0px;
  }
`
